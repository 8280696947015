import React from "react";

function TopInfo({ items }) {
  return (
    <div id="top-info-card"  >
      <div className="content">
        <div className="text">
          <div className="title"> {items.title}</div>
          <div className="subtitle">{items.subtitle}</div>
        </div>
        <div className="card-icon">
          <img src={items.icon} alt="icon" />
        </div>
      </div>
      <div className="count">{items.count}</div>
    </div>
  );
}

export default TopInfo;
