import axios from "axios";
import { apiPath } from "../constants/config";

const api = axios.create({
  baseURL: apiPath,
});

// User
export const getRoomCounts = () => api.get("/group_counts");

export default {
  getRoomCounts,
};
