export const topInfo = [
  {
    id: "total-emp",
    title: "Total Employees",
    subtitle: "EXPO",
    count: "10",
    icon: "./img/top-info-1.svg",
  },
  {
    id: "population",
    title: "Employees in the Area",
    subtitle: "Expo Area",
    count: "10",
    icon: "./img/top-info-2.svg",
  },
  {
    id: "first-room",
    title: "First Rooom",
    subtitle: "Total Employees",
    icon: "./img/top-info-3.svg",
  },
  {
    id: "second-room",
    title: "Second Rooom",
    subtitle: "Total Employees",
    icon: "./img/top-info-4.svg",
  },
  {
    id: "third-room",
    title: "Third Rooom",
    subtitle: "Total Employees",
    icon: "./img/top-info-5.svg",
  },
];
