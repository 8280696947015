import React from "react";

function MobileHeader() {
  return (
    <div className="mobile-header nonvisible">
      <div className="icon-hover">
        <img src="./img/menu.svg" alt="Menu" />
      </div>

      <div className="user-info">
        <div className="icon-hover">
          <img src="./img/language.svg" alt="Language" />
        </div>
        <div className="icon-hover">
          <img src="./img/notif.svg" alt="Notification" />
        </div>

        <div className="user-image">
          <img src="./img/person.svg" alt="User" />
        </div>
      </div>
    </div>
  );
}

export default MobileHeader;
