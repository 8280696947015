import {
  HomeIcon,
  ChartIcon,
  UserManageIcon,
  DepartmentIcon,
  SettingsIcon,
  GraphIcon,
} from "./icons";

export const menu = [
  {
    title: "Home Page",
    icon: <HomeIcon />,
  },
  {
    title: "Dashboard",
    icon: <ChartIcon />,
  },
  {
    title: "Analyzes",
    icon: <GraphIcon />,
  },
];
