import classNames from "classnames";
import React, { useEffect, useState } from "react";
import UserCounter from "../UserCounter";
import Notification from "./Notification";

function MainCard({
  lastCheck,
  firstRoomCount,
  secondRoomCount,
  thirdRoomCount,
  toastList,
}) {
  return (
    <div id="main-card">
      <div className="header">
        <Notification
          toastList={toastList}
          position="top-right"
          autoDelete={true}
          autoDeleteTime={3000}
        />
        <div className="icon">
          <img src="./img/location.svg" alt="" />
        </div>
        <div className="content">
          <div className="title">Area Stream</div>
          <div className="subtitle">Every thing easy with one step.</div>
        </div>
      </div>

      <div className="m-plus-safety-image">
        <div className="user-counter first-user-counter">
          <UserCounter userCount={firstRoomCount} />
        </div>
        <div className="user-counter second-user-counter">
          <UserCounter userCount={secondRoomCount} />
        </div>
        <div className="user-counter third-user-counter">
          <UserCounter userCount={thirdRoomCount} />
        </div>

        <img
          src="./img/m+safety.svg"
          alt="M Plus Safety"
          className="hide-m-plus-safety"
        />

        <img
          src="./img/m+safety-mobile.svg"
          alt="M Plus Safety"
          className="hide-m-plus-safety-mobile mobile-vector"
        />

        <div className="auth-control">
          <img
            src="./img/on_board.svg"
            alt="On Board"
            className={classNames("onboard", {
              active: lastCheck === "stable",
            })}
          />

          <img
            src="./img/verified.svg"
            alt="Verified"
            className={classNames("verified", {
              active: lastCheck === true,
            })}
          />

          <img
            src="./img/access_denied.svg"
            alt="Access Denied"
            className={classNames("access-denied", {
              active: lastCheck === false,
            })}
          />
        </div>

        <div className="door">
          <img src="./img/door.svg" alt="M Plus Safety" />
        </div>
      </div>
    </div>
  );
}

export default MainCard;
