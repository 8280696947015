import { useState, useEffect } from "react";

const Notification = (props) => {
  const { toastList, position, autoDelete, autoDeleteTime } = props;
  const [list, setList] = useState(toastList);

  useEffect(() => {
    const reversed = [...toastList].reverse();
    setList(reversed);
  }, [toastList]);

  const deleteToast = (id) => {
    document.getElementById(`notification-${id}`)?.classList.add("animate__fadeOutRight");

    setTimeout(() => {
      deleteItem(id);
    }, 600);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toastList.length && list.length) {
        document.getElementById(`notification-${toastList[0].id}`)?.classList.add("animate__fadeOutRight");

        const timer = setTimeout(() => {
          deleteToast(toastList[0].id);
        }, 600);

        return () => clearTimeout(timer);
      }
    }, autoDeleteTime);

    return () => {
      clearInterval(interval);
    };
  }, [toastList, autoDelete, autoDeleteTime, list]);

  const deleteItem = (id) => {
    const listItemIndex = list.findIndex((e) => e.id === id);
    const toastListItem = toastList.findIndex((e) => e.id === id);
    list.splice(listItemIndex, 1);
    toastList.splice(toastListItem, 1);
    setList([...list]);
  };

  return (
    <>
      <div className={`notification-container  ${position}`}>
        {list.map((toast) => (
          <div
            key={toast.id}
            className={`notification toasts animate__animated animate__fadeInRight ${toast.type}-toast`}
            id={`notification-${toast.id}`}
          >
            <div className="notification-image">
              {toast.type === "error" && <img src="./img/verify-error.svg" alt="Error" />}

              {toast.type === "success" && <img src="./img/verify-success.svg" alt="Success" />}
            </div>

            <div className="notification-content">
              <div className="notification-title">{toast.title}</div>
              <div className="notification-message">{toast.description}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Notification;
