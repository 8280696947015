import React from "react";

function Footer() {
  return (
    <div className="footer">
      <div className="copyright">
        Copyright Mekap © 2022. All Rights Reserved
      </div>
      <div className="trademark">
        <div className="msafety">
          <img src="./img/msafetylogo.svg" alt="m+safety logo" /> <hr />
        </div>

        <div className="mekap">
          <div className="text">Trademark of</div>
          <img src="./img/mekap-logo.svg" alt="mekaplogo" />
        </div>
      </div>
    </div>
  );
}

export default Footer;
