import React, { useState, useEffect } from "react";
import { menu } from "../../constants/menu";
import classNames from "classnames";
import { ResetIcon } from "../../constants/icons";
function NavBar({ onReset }) {
  const [active, setActive] = useState(0);

  return (
    <div id="navbar">
      <div className="content">
        <div className="logo">
          <img src="./img/logo.svg" alt="logo" />
        </div>
        <div className="user">
          <img src="./img/person.svg" alt="user" className="user-img" />

          <div className="user-icons">
            <div className="icon">
              <img src="./img/logout.svg" alt="logout" />
            </div>
            <div className="icon">
              <img src="./img/notif.svg" alt="notification" />
            </div>
          </div>
          <div className="line">
            <hr />
          </div>
        </div>
        <div className="menu">
          {menu.map((item, index) => {
            return (
              <div className="menu-item" key={index + "z"}>
                <div
                  className={classNames("content", {
                    "active-content": index === 1,
                  })}
                >
                  <div className="menu-icon">
                    <span
                      className={classNames({
                        "active-icon": active === index,
                      })}
                    >
                      {item.icon}
                    </span>
                  </div>
                  <div className="menu-title">{item.title}</div>
                </div>
                {active !== index && (
                  <div className="line">
                    <hr />
                  </div>
                )}
              </div>
            );
          })}
          <div className="menu-item" key="ascd">
            <div className={classNames("content")} onClick={onReset}>
              <div className="menu-icon">
                <span>
                  <ResetIcon />
                </span>
              </div>
              <div className="menu-title">Reset</div>
            </div>
          </div>
        </div>
      </div>

      <div className="menu-footer">
        <div className="icon">
          <img src="./img/language.svg" alt="language" />
        </div>
      </div>
    </div>
  );
}

export default NavBar;
