import React from "react";

function UserCard({item}) {
  return (
    <div className="content">
      <div className="profile">
        <img src={item.icon} alt="User Profile" />
      </div>
      <div className="text">
        <div className="name">{item.title}</div>
        <div className="role">{item.subtitle}</div>
      </div>
    </div>
  );
}

export default UserCard;
