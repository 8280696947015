export const userInfo = [
  {
    id: "MKP221022003",
    title: "Queenie Stone",
    subtitle: "Staff",
    icon: "./img/person.svg",
  },
  {
    id: "MKP221022010",
    title: "Ambrose George",
    subtitle: "Employee",
    icon: "./img/person.svg",
  },
  {
    id: "MKP221022018",
    title: "Celia Poe",
    subtitle: "Employee",
    icon: "./img/person.svg",
  },
  {
    id: "MKP221022005",
    title: "Wilfrid Clark",
    subtitle: "Employee",
    icon: "./img/person.svg",
  },
  {
    id: "MKP221022007",
    title: "Katherine Long",
    subtitle: "Employee",
    icon: "./img/person.svg",
  },
  {
    id: "MKP221022012",
    title: "Bennett Twitty",
    subtitle: "Employee",
    icon: "./img/person.svg",
  },
  {
    id: "MKP221022014",
    title: "Prunella Daniel",
    subtitle: "Employee",
    icon: "./img/person.svg",
  },
  {
    id: "MKP221022016",
    title: "Percy Snider",
    subtitle: "Employee",
    icon: "./img/person.svg",
  },
  {
    id: "MKP221022001",
    title: "Bryant Ballard",
    subtitle: "Superior ",
    icon: "./img/person.svg",
  },
  {
    id: "MKP221022009",
    title: "Will Baldwin",
    subtitle: "Rowan Rose",
    icon: "./img/person.svg",
  },
];
