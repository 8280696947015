import NavBar from "./components/Global/NavBar";
import React, { useEffect, useState } from "react";
import TopInfo from "./components/Global/TopInfo";
import { topInfo } from "./constants/top-info";
import MainCard from "./components/Global/MainCard";
import UserCard from "./components/Global/UserCard";
import { userInfo } from "./constants/user-info";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { ArrowIcon } from "./constants/icons";
import Footer from "./components/Global/Footer";
import MobileHeader from "./components/Global/MobileHeader";
import { io } from "socket.io-client";
import { getRoomCounts, getRoomPersonss } from "./api";

const socket = io("https://macula.retinaarge.com.tr:5006");

socket.on("connect", function () {
  console.log("Connected to SocketIO...");
});

function App() {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [lastCheck, setLastCheck] = useState("stable");
  const [firstRoomPersons, setFirstRoomPersons] = useState([]);
  const [secondRoomPersons, setSecondRoomPersons] = useState([]);
  const [thirdRoomPersons, setThirdRoomPersons] = useState([]);

  const [firstRoomCount, setFirstRoomCount] = useState(0);
  const [secondRoomCount, setSecondRoomCount] = useState(0);
  const [thirdRoomCount, setThirdRoomCount] = useState(0);

  const [toastList, setToastList] = useState([]);
  let timer = null;

  useEffect(() => {
    document.addEventListener("scroll", scrollHandler);
    getCounts();

    socket.on("connect", () => {
      setIsConnected(true);
      console.log("connected");
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    socket.on("door_passed", async function (data) {
      console.log(data);
      setLastCheck(true);
      showPopup("success", "Passed");

      setFirstRoomPersons(data[0].persons);
      setFirstRoomCount(data[0].count);

      setSecondRoomPersons(data[1].persons);
      setSecondRoomCount(data[1].count);

      setThirdRoomPersons(data[2].persons);
      setThirdRoomCount(data[2].count);
    });

    socket.on("door_fail", async function (data) {
      setLastCheck(false);
      showPopup("error", "Unauthorized");

      setFirstRoomPersons(data[0].persons);
      setFirstRoomCount(data[0].count);

      setSecondRoomPersons(data[1].persons);
      setSecondRoomCount(data[1].count);

      setThirdRoomPersons(data[2].persons);
      setThirdRoomCount(data[2].count);
    });

    socket.on("door_authorized", async function (data) {
      console.log(data);
      setLastCheck(true);
      showPopup("success", "Authorized");

      setFirstRoomPersons(data[0].persons);
      setFirstRoomCount(data[0].count);

      setSecondRoomPersons(data[1].persons);
      setSecondRoomCount(data[1].count);

      setThirdRoomPersons(data[2].persons);
      setThirdRoomCount(data[2].count);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("pong");
      if (timer) clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (lastCheck === "stable") return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timer = setTimeout(() => {
      setLastCheck("stable");
    }, 5000);
  }, [lastCheck]);

  const showPopup = (type, title, description) => {
    const id = Math.floor(Math.random() * 1000 + 1);
    const toast = { id, type, title, description };
    setToastList([...toastList, toast]);
  };

  const scrollHandler = () => {
    const scroll = window.scrollY;
    const windowHeight = window.innerHeight;
    const navBar = document.querySelector("#navbar");
    const navBarHeight = navBar.offsetHeight;
    const transitionPoint = Math.abs(windowHeight - (navBarHeight + 190));

    if (scroll <= transitionPoint) {
      navBar.style.transform = `translateY(-${scroll}px)`;
    }
  };

  const resetData = () => {
    setLastCheck("stable");
    setToastList([]);
    if (isConnected) {
      socket.emit("reset", {});
    }
  };

  const getCounts = async () => {
    try {
      const { data } = await getRoomCounts();
      console.log(data);
      setFirstRoomCount(data[0].count);
      setSecondRoomCount(data[1].count);
      setThirdRoomCount(data[2].count);
    } catch (error) {}
  };

  return (
    <div id="dashboard-layout">
      <div id="dashboard">
        <div className="container-fluid dashboard-container">
          <div className="row">
            <div className="col-12">
              <div className="top-info">
                {topInfo.map((item, index) => {
                  if (item.id === "population")
                    item.count =
                      firstRoomCount + secondRoomCount + thirdRoomCount;
                  if (item.id === "first-room") item.count = firstRoomCount;
                  if (item.id === "second-room") item.count = secondRoomCount;
                  if (item.id === "third-room") item.count = thirdRoomCount;
                  return <TopInfo key={index} items={item} />;
                })}
              </div>
            </div>
            <div className="col-lg-8">
              <MainCard
                lastCheck={lastCheck}
                setLastCheck={setLastCheck}
                firstRoomCount={firstRoomCount}
                secondRoomCount={secondRoomCount}
                thirdRoomCount={thirdRoomCount}
                toastList={toastList}
              />
            </div>
            <div className="col-lg-4 user-management">
              <div id="user-card">
                <div className="swiper-top">
                  <div className="title">First Room</div>
                  <div className="swiper-buttons">
                    <button className="back-btn back">
                      <ArrowIcon />
                    </button>
                    <button className="next-btn next">
                      <ArrowIcon />
                    </button>
                  </div>
                </div>

                <Swiper
                  spaceBetween={20}
                  slidesPerView={1.5}
                  loop={false}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    960: {
                      slidesPerView: 2.5,
                      spaceBetween: 20,
                    },
                  }}
                  navigation={{ nextEl: ".next-btn", prevEl: ".back-btn" }}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                >
                  {userInfo
                    .filter((user) => firstRoomPersons.includes(user.id))
                    .map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <UserCard item={item} />
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
              <div id="user-card">
                <div className="swiper-top">
                  <div className="title">Second Room</div>
                  <div className="swiper-buttons">
                    <button className="second-back-btn back">
                      <ArrowIcon />
                    </button>
                    <button className="second-next-btn next">
                      <ArrowIcon />
                    </button>
                  </div>
                </div>

                <Swiper
                  slidesPerView={1.5}
                  spaceBetween={20}
                  loop={false}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={{
                    nextEl: ".second-next-btn",
                    prevEl: ".second-back-btn",
                  }}
                  breakpoints={{
                    960: {
                      slidesPerView: 2.5,
                      spaceBetween: 20,
                    },
                  }}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                >
                  {userInfo
                    .filter((user) => secondRoomPersons.includes(user.id))
                    .map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <UserCard item={item} />
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
              <div id="user-card">
                <div className="swiper-top">
                  <div className="title">Third Room (Need Authorize)</div>
                  <div className="swiper-buttons">
                    <button className="third-back-btn back">
                      <ArrowIcon />
                    </button>
                    <button className="third-next-btn next">
                      <ArrowIcon />
                    </button>
                  </div>
                </div>

                <Swiper
                  slidesPerView={1.5}
                  spaceBetween={20}
                  loop={false}
                  breakpoints={{
                    960: {
                      slidesPerView: 2.5,
                      spaceBetween: 20,
                    },
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={{
                    nextEl: ".third-next-btn",
                    prevEl: ".third-back-btn",
                  }}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                >
                  {userInfo
                    .filter((user) => thirdRoomPersons.includes(user.id))
                    .map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <UserCard item={item} />
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
              <div className="user-path">
                <div className="path">
                  <div className="title">User Management</div>
                  <img
                    src="./img/path-arrow.svg"
                    alt="Path Arrow"
                    className="path-icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavBar onReset={resetData} />
      <MobileHeader />
      <Footer />
    </div>
  );
}

export default App;
