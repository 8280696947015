import React, { Fragment, useState } from "react";

function UserCounter({ userCount }) {
  let arr = new Array(userCount).fill(0);

  return (
    <>
      {arr.map((item, index) => {
        return (
          <Fragment key={index}>
            <img src="./img/user.svg" alt="user" />
            {index === 4 && <br />}
            {index === 7 && <br />}
            {index === 9 && <br />}
          </Fragment>
        );
      })}
    </>
  );
}

export default UserCounter;
